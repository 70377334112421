// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fade {
    0%   { opacity: 0.3; }
    50%  { opacity: 1; }
    100% { opacity: 0.3; }
}

.loading-dots i {
    font-size: 1rem;
    margin: 0 0.25rem;
    opacity: 0.3;
    animation: fade 1.5s infinite;
}

.loading-dots i:nth-child(2) {
    animation-delay: 0.5s;
}

.loading-dots i:nth-child(3) {
    animation-delay: 1s;
}`, "",{"version":3,"sources":["webpack://./src/styles/PredictComponentStyles.css"],"names":[],"mappings":"AAAA;IACI,OAAO,YAAY,EAAE;IACrB,OAAO,UAAU,EAAE;IACnB,OAAO,YAAY,EAAE;AACzB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,6BAA6B;AACjC;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["@keyframes fade {\n    0%   { opacity: 0.3; }\n    50%  { opacity: 1; }\n    100% { opacity: 0.3; }\n}\n\n.loading-dots i {\n    font-size: 1rem;\n    margin: 0 0.25rem;\n    opacity: 0.3;\n    animation: fade 1.5s infinite;\n}\n\n.loading-dots i:nth-child(2) {\n    animation-delay: 0.5s;\n}\n\n.loading-dots i:nth-child(3) {\n    animation-delay: 1s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
